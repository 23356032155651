import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_CMS_BLOCKS, ICmsBlock } from 'graphql/cms/query';
import { generateKey } from 'ui/util/generate-key';
import { InnerHtml } from 'ui/component/inner-html';

export const ContentAdditional = () => {
    // TODO: make identifiers dynamic based on configurations or based on route
    const getIdentifiers = () => ['cms-home-page-content-additional'];

    const {
        data,
        error,
    } = useQuery(GET_CMS_BLOCKS, {
        variables: {
            identifiers: getIdentifiers(),
        },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-and-network',
    });

    if (error) return null;
    if (!data?.cmsBlocks?.items?.length) return null;
    return (
        <>
            {
                data && data.cmsBlocks.items.map((block: ICmsBlock, index) => (
                    block && (
                        <InnerHtml html={block.content.toString()} key={generateKey([index])} />
                    )
                ))
            }
        </>
    );
};
