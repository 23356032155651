import React from 'react';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import { usePortalInfo } from '@silkpwa/module/portal';
import { HomePageReviewsWidget } from 'ui/component/product/product-widget/reviews/home-page';

export const Reviews = () => {
    const config = useConfig();
    const portalInfo = usePortalInfo();
    if ((portalInfo.isPortal && portalInfo.isPortalActive) && !config.extension_attributes?.cw_reviews_enabled) {
        return null;
    }
    if (config.extension_attributes?.cw_reviews_enabled && config.extension_attributes.reviews_homepage_enabled) {
        return (
            <HomePageReviewsWidget />
        );
    }
    return null;
};
