import { gql, TypedDocumentNode } from '@apollo/client';

export interface ICmsBlock {
    content: string;
    identifier: string;
    title: string;
}

export interface ICmsBlockItems {
    items: ICmsBlock[];
}

export interface ICmsBlocks {
    cmsBlocks: ICmsBlockItems;
}

export const GET_CMS_BLOCKS: TypedDocumentNode<ICmsBlocks> = gql`
    query GET_CMS_BLOCKS(
        $identifiers: [String]
    ) {
        cmsBlocks(identifiers: $identifiers) {
            items {
                content
                identifier
                title
            }
        }
    }
`;
