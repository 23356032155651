import React from 'react';
import { Head } from './head';
import { Content } from './content';
import { ContentAdditional } from './content-additional';

const HomePage = () => (
    <div data-page-type="home">
        <Head />
        <ContentAdditional />
        <Content />
    </div>
);

export { HomePage as default };
