import React, { createElement, useEffect, useRef } from 'react';

export interface IInnerHtmlProps {
    html: string;
    allowRerender?: boolean;
}

export const InnerHtml: React.FC<IInnerHtmlProps> = ({
    html,
    allowRerender = false,
    ...rest
}) => {
    const divRef = useRef(null);
    const isFirstRender = useRef(true);

    useEffect(() => {
        const host = divRef.current;
        if (!html || !host) return;
        if (!isFirstRender.current) return;
        isFirstRender.current = Boolean(allowRerender);
        const slotHtml = document.createRange().createContextualFragment(html);
        host.innerHTML = '';
        host.appendChild(slotHtml);
        const scripts = host.querySelectorAll('script');
        // loads external js from cmsContent
        scripts.forEach((script) => {
            const scriptClone = document.createElement('script');
            if (script.src) {
                scriptClone.src = script.src;
                scriptClone.defer = true;
                host.appendChild(scriptClone);
                script.remove();
            }
        });
        // Adds custom js from cmsContent
        document.addEventListener('DOMContentLoaded', () => {
            scripts.forEach((script) => {
                const scriptClone = document.createElement('script');
                if (!script.src) {
                    scriptClone.textContent = script.textContent;
                    host.appendChild(scriptClone);
                    script.remove();
                }
            });
        });
    }, [html, divRef]);

    return createElement('div', { ...rest, ref: divRef });
};
